import "../App.css";
import { Tooltip } from "@mui/material";
import { ProgressiveRendering } from "../components/ProgressiveRendering";
import { ClippyScene } from "../components/ClippyScene";
function Root() {
  const name = "adam-jablonka";
  return (
    <div className="App">
      <header className="App-header">
        {/* <div className="root-photo">
          <ProgressiveRendering>
            <img src="cat-yawn.png" style={{ zIndex: "0" }} />
            <ClippyScene />
          </ProgressiveRendering>
        </div> */}
        <ClippyScene />
        <Tooltip
          title="Bachelors in Computer Science; Minor in Mathematics"
          arrow
          placement="top"
        >
          <samp
            style={{ position: "absolute", marginTop: "15rem", zIndex: "1" }}
            className="blinking-cursor"
          >
            {name}
          </samp>
        </Tooltip>
      </header>
      <body>
        <a href="/contact" className="links-container">
          <samp>contact-me</samp>
        </a>
        <a href="https://github.com/AdamJablonka" className="links-container">
          <samp>github</samp>
        </a>
        <a href="/work" className="links-container">
          <samp>my-work</samp>
        </a>
        <a href="/about" className="links-container">
          <samp>about</samp>
        </a>
      </body>
    </div>
  );
}

export default Root;
